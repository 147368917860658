import type { OAuthProfile } from '~/types/auth';

import { toast } from 'vue-sonner';
import { withQuery } from 'ufo';

export const useOAuth = defineStore('oauth', {
  state: () => ({
    fb: null as unknown,
    google: null as null | GoogleOAuth2Client,
    profile: null as null | OAuthProfile,
    socialToken: null as null | string,
  }),
  actions: {
    init(context: AuthContext, params = {} as InitParams) {
      if (!import.meta.client || !window.google) return;
      const config = useRuntimeConfig();

      if (config.public.googleClientId) {
        const cb = params.onSuccess ?? this.redirectUser;
        const scope = (...s: string[]) => s.map((s) => `https://www.googleapis.com/auth/${s}`).join(' ');

        this.google = window.google.mvGoogleLogin = window.google.accounts.oauth2.initTokenClient({
          client_id: config.public.googleClientId,
          callback: (g) => this.callback({ google: g }, cb),
          error_callback: params.onError,
          scope: scope('userinfo.email', 'userinfo.profile'),
        });
      }

      if (config.public.facebookAppId) {
        // TODO: Facebook OAuth
      }
    },

    requestGoogleToken() {
      if (!this.google) return toast.error('Google login is not available right now');
      try {
        window.google.mvGoogleLogin?.requestAccessToken();
      } catch (e) {
        const { $sentry } = useNuxtApp();
        $sentry.withScope((scope) => {
          scope.setExtra('OAuthaException', 'Google Login Failed');
          $sentry.captureException(e);
        });
      }
    },

    async redirectUser(code?: string) {
      const auth = useAuth();
      const client = useCookie('client-data');

      client.value = null;

      if (auth.client?.mapping) {
        await new Promise((r) => setTimeout(r, 1000));
        navigateTo('/authorize/mapping', { replace: true });
        return;
      }

      const url = withQuery(auth.client!.redirectURI, {
        code: code || auth.client!.code,
        state: auth.client?.state,
      });

      navigateTo(url, { external: true, replace: true });
    },

    async callback(payload: CallbackPayload, cb: InitParams['onSuccess']): Promise<void> {
      const { $i18n } = useNuxtApp();
      const { fb, google } = payload;

      const gtm = useGTM();
      const auth = useAuth();
      const route = useRoute();

      if (google) {
        gtm.clickLogin('loginGoogle');
        const gapis = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${google.access_token}`;
        const googUser = await $fetch<GoogleUserInfo>(gapis);

        try {
          this.profile = { email: googUser.email, fullName: googUser.name, avatar: googUser.picture };
        } catch {}

        if (route.path.includes('/form/login')) {
          const { exist } = await auth.check(googUser.email);
          if (!exist) {
            this.socialToken = google.access_token;
            toast.info($i18n.t('login.notRegisteredToast'), { duration: 5000 });
            navigateTo(route.path.replace('/login', '/register'));
            return;
          }
        }

        const { data, error } = await auth.socialLogin('google', google.access_token);
        if (error) toast.error(error.detail || 'Unknown Error');
        else cb?.(data.code);
      } else if (fb) {
        console.log('Facebook OAuth');
        // TODO: Facebook OAuth
      }
    },
  },
});

interface InitParams {
  onError?: (e: any) => void;
  onSuccess?: (accessToken: string) => void;
}

type AuthContext = 'login' | 'register';

type CallbackPayload = { fb: object; google?: never } | { fb?: never; google: GoogleTokenRes };
